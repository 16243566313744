import {makeAutoObservable} from "mobx";
import Distipline_settingsService from "./services/distipline_settings.service";
import {toast} from "react-toastify";

export class DistiplineSettingsStore {
    brigade = {}
    age_group = []
    distipline = []
    factors = []
    factors_coff = []
    coach = []
    figure = []
    table = {}
    loading = false
    loadingBrigade = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoading(loading) {
        this.loading = loading
    }

    setLoadingSetBrigade(loadingBrigade) {
        this.loadingBrigade = loadingBrigade
    }

    setAgeGroup(age_group) {
        this.age_group = age_group
    }

    setDistipines(distipline) {
        this.distipline = distipline
    }

    setCoach(coach) {
        this.coach = coach
    }

    setFactors(factors) {
        this.factors = factors
    }

    setFactorsCoff(factors_coff) {
        this.factors_coff = factors_coff
    }

    setBrigade(brigade) {
        this.brigade = brigade
    }

    setTable(table) {
        this.table = table
    }

    setFigure(figure) {
        this.figure = figure
    }

    async getBrigade(id) {
        try {
            const res = await Distipline_settingsService.getBrigade(id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                this.setBrigade(res.data)
            }

        } catch (e) {
            console.log(e)
        }
    }

    async setBrigadeFigure(id, payload) {
        this.setLoadingSetBrigade(true)
        try {
            const res = await Distipline_settingsService.setBrigadeFigure(id, payload)
            if (res.data.status === "ok") {
                toast.success('Сохранено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingSetBrigade(false)
        }
    }

    async setFigureTable(id_figure, id) {
        try {
            const res = await Distipline_settingsService.setFigure(id_figure, id)
            // this.setTable(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async setSaveFigure(payload) {
        try {
            const res = await Distipline_settingsService.setSaveFigure(payload)

            if (res.data.status === "ok") {
                toast.success('Сохранено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            // this.setTable(res.data)
        } catch (e) {
            console.log(e)
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    async getTable(id) {
        try {
            const res = await Distipline_settingsService.getTable(id)
            this.setTable(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async getFigure() {
        try {
            const res = await Distipline_settingsService.getFigure()
            this.setFigure(res.data.figure)
        } catch (e) {
            console.log(e)
        }
    }

    async getSettingsAgeGroup() {

        try {
            const res = await Distipline_settingsService.getSettingsAgeGroup()
            this.setAgeGroup(res.data.age_group)
        } catch (e) {
            console.log(e)
        }
    }

    async getSettingsDistiplines(id) {
        try {
            const res = await Distipline_settingsService.getSettingsDistiplines(id)
            this.setDistipines(res.data.discipline)
        } catch (e) {
            console.log(e)
        }
    }

    async getFactors(id) {
        try {
            const res = await Distipline_settingsService.getFactors(id)
            this.setFactors(res.data.factors)
        } catch (e) {
            console.log(e)
        }
    }

    async getFactorsCoff(id) {
        try {
            const res = await Distipline_settingsService.getFactorsCoff(id)
            this.setFactorsCoff(res.data.basemarks)
        } catch (e) {
            console.log(e)
        }
    }

    async getSettingsCouch(id) {
        try {
            const res = await Distipline_settingsService.getSettingsCouch(id)
            this.setCoach(res.data.age_group_discipline_coach)
        } catch (e) {
            console.log(e)
        }
    }

    async createReserv(age_group_id, discipline_id, discipline_stage_id) {
        try {
            const res = await Distipline_settingsService.createReserv(age_group_id, discipline_id, discipline_stage_id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Сформировано', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async createReservFigure(age_group_id) {
        try {
            const res = await Distipline_settingsService.createReservFigure(age_group_id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Сформировано', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async downloadLinkReserv(age_group_id, discipline_id, discipline_stage_id) {
        try {
            const res = await Distipline_settingsService.downloadLinkReserv(age_group_id, discipline_id, discipline_stage_id)

            const url = window.URL.createObjectURL(new Blob([res.data]));
            console.log(url)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e)
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    async downloadLinkReservFigure(age_group_id) {
        try {
            const res = await Distipline_settingsService.downloadLinkReservFigure(age_group_id)

            const url = window.URL.createObjectURL(new Blob([res.data]));
            console.log(url)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e)
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    async setReserv(callback, age_group_id, discipline_id, draw_variant, discipline_stage_id) {
        try {
            const res = await Distipline_settingsService.setReserv(age_group_id, discipline_id, draw_variant, discipline_stage_id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                callback()
                toast.success('Сохранено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async setReservFigure(callback, age_group_id, draw_variant) {
        try {
            const res = await Distipline_settingsService.setReservFigure(age_group_id, draw_variant)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                callback()
                toast.success('Сохранено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async setSettingsCouch(payload) {
        try {
            const res = await Distipline_settingsService.setSettingsCouch(payload)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Судьи успешно сохранены', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getListRes(callback, payload) {
        try {
            const res = await Distipline_settingsService.getListRes(payload)
            callback(res?.data?.competition_application_discipline?.sort((a, b) => {
                const drawA = a?.competition_draw;
                const drawB = b?.competition_draw;
                if (drawA === null && drawB !== null) {
                    return -1;
                } else if (drawA !== null && drawB === null) {
                    return 1;
                } else if (drawA === null && drawB === null) {
                    return 0;
                }
                return drawA - drawB;
            })
            )
        } catch (e) {
            console.log(e)
        }
    }

    async getListResFig(callback, payload) {
        try {
            const res = await Distipline_settingsService.getListResFig(payload)
            callback(res?.data?.application_figure?.sort((a, b) => a?.competition_draw - b?.competition_draw))
        } catch (e) {
            console.log(e)
        }
    }

    async saveListRes(callback, payload) {
        try {
            const res = await Distipline_settingsService.saveListRes(payload)

            callback()

            toast.success('Сохранено', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (e) {
            console.log(e)
        }
    }

    async saveListResFig(callback, payload) {
        try {
            const res = await Distipline_settingsService.saveListResFig(payload)

            callback()

            toast.success('Сохранено', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (e) {
            console.log(e)
        }
    }
}
