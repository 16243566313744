import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {observer} from "mobx-react-lite";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useStore} from "../../../hooks/useStore";

const Header = observer(({
                             ageGroupSelect,
                             setAgeGroupSelect,
                             setDistiplineSelect,
                             distiplineSelect,
                             setDistiplineSelectFull
                         }) => {
    const store = useStore()

    return (
        <div className={s.header}>
            <Wrapper>
                <div className={s.header_data}>
                    <FormControl fullWidth sx={{
                        maxWidth: '254px',
                        height: '45px'
                    }}>
                        <Select
                            placeholder={'Возрастная группа'}
                            displayEmpty
                            value={ageGroupSelect}
                            onChange={(e) => {
                                setAgeGroupSelect(e.target.value)
                                setDistiplineSelect(null)
                                setDistiplineSelectFull(null)
                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '45px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Возрастная группа</MenuItem>
                            {store.distipline_settings.age_group.map((el, i) => <MenuItem key={el.age_group_id}
                                                                                          value={el.age_group_id}>{`${el.age_group_name}`}</MenuItem>)}

                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{
                        maxWidth: '289px',
                        height: '45px',
                        marginLeft: '22px',
                        marginRight: '22px',
                    }}>
                        <Select
                            placeholder={'Дисциплина'}
                            disabled={ageGroupSelect === null}
                            displayEmpty
                            value={distiplineSelect}
                            onChange={(e) => {
                                setDistiplineSelect(e.target.value)

                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '45px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Дисциплина</MenuItem>
                            {store.distipline_settings.distipline.map((el, i) => <MenuItem
                                onClick={() => setDistiplineSelectFull(el)} key={`${el.discipline_id}/${el.discipline_stage_id}`}
                                value={`${el.discipline_id}/${el.discipline_stage_id}`}>{el.discipline_name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <div>
                        <h1 className={s.name}>{store?.global?.status?.competition_name || ''}</h1>
                    </div>
                </div>
            </Wrapper>

        </div>
    );
});

export default Header;
