import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import {createContext} from "react";
import {store} from "./store/store";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Context.Provider value={{store: store}}>
        <CssBaseline/>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <App/>
    </Context.Provider>
    // {/*</React.StrictMode>*/}
);

