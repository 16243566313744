import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Header from "./header";
import {observer} from "mobx-react-lite";
import {useStore} from "../../hooks/useStore";
import Wrapper from "../../components/wrapper";
import Judges from "./judges";
import Reserv from "./reserv";
import Factor from "./factor";
import ReservFigure from "./reserv_figure";
import JudgesFigure from "./judges_figure";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import api from "../../api/api";

const SettingsDistiplines = observer(() => {
    const store = useStore()

    const [ageGroupSelect, setAgeGroupSelect] = useLocalStorage('ageGroupSelect', null)
    const [distiplineSelect, setDistiplineSelect] = useLocalStorage('distiplineSelect', null)
    const [distiplineSelectFull, setDistiplineSelectFull] = useLocalStorage('distiplineSelectFull', null)
    const [choosePage, setChoosePage] = useState(1)

    useEffect(() => {
        if (distiplineSelectFull?.discipline_id === 1) {
            setChoosePage(1)
        }
    }, [distiplineSelectFull?.discipline_id])

    useEffect(() => {
        const getSettingsAgeGroup = async () => {
            await store.distipline_settings.getSettingsAgeGroup()
        }

        getSettingsAgeGroup()
    }, [])
    useEffect(() => {
        const getSettingsDistiplines = async () => {
            await store.distipline_settings.getSettingsDistiplines(ageGroupSelect)
        }
        if (ageGroupSelect === null) return

        getSettingsDistiplines()
    }, [ageGroupSelect])
    return (
        <div>
            <Header ageGroupSelect={ageGroupSelect} setDistiplineSelect={setDistiplineSelect}
                    setDistiplineSelectFull={setDistiplineSelectFull}
                    distiplineSelect={distiplineSelect} setAgeGroupSelect={setAgeGroupSelect}/>
            <div className={s.choose_page}>
                <Wrapper>
                    <div className={s.navigate} style={{
                        gridTemplateColumns: `repeat(3,1fr)`
                    }}>
                        <div className={s.line} style={{
                            width: `calc(100% / 3)`,
                            left: `${(100 / 3) * (choosePage - 1)}%`
                        }}/>

                        {distiplineSelectFull?.discipline_id === 1 ? <>
                            <div onClick={() => setChoosePage(1)} className={s.navigate_item}>Судьи</div>
                            <div onClick={() => setChoosePage(2)} className={s.navigate_item}>Жеребьевка</div>
                        </> : <>
                            <div onClick={() => setChoosePage(1)} className={s.navigate_item}>Судьи</div>
                            <div onClick={() => setChoosePage(2)} className={s.navigate_item}>Жеребьевка / Резерв</div>
                            <div onClick={() => setChoosePage(3)} className={s.navigate_item}>Факторы</div>
                        </>}


                    </div>
                </Wrapper>
            </div>

            <div className={s.content}>
                {ageGroupSelect !== null && distiplineSelectFull !== null ?
                    <>
                        {distiplineSelectFull?.discipline_id === 1 ? <Wrapper>
                            {choosePage === 1 &&
                                <JudgesFigure ageGroupSelect={ageGroupSelect} distiplineSelect={distiplineSelectFull}/>}
                            {choosePage === 2 &&
                                <ReservFigure age_group_id={ageGroupSelect}
                                              distiplineSelectFull={distiplineSelectFull}
                                              discipline_id={distiplineSelectFull?.discipline_id}/>}
                        </Wrapper> : <Wrapper>
                            {choosePage === 1 &&
                                <Judges ageGroupSelect={ageGroupSelect} distiplineSelect={distiplineSelectFull}/>}
                            {choosePage === 2 &&
                                <Reserv age_group_id={ageGroupSelect}
                                        distiplineSelectFull={distiplineSelectFull}
                                        discipline_id={distiplineSelectFull?.discipline_id}/>}
                            {choosePage === 3 && <Factor distiplineSelect={distiplineSelectFull}/>}
                        </Wrapper>}
                    </>
                    : <Wrapper>
                        <h1 className={s.empty_text}>Выберите возрастную группу и дисциплину</h1>
                    </Wrapper>}
            </div>
        </div>
    );
});

export default SettingsDistiplines;
