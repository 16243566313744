import React, {useState} from 'react';
import s from './styles.module.css'
import {FormControl, MenuItem, Select} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import classNames from "classnames";

const Row = observer(({
                          index,
                          result_dinamic_type_name,
                          disciplines,
                          result,
                          result_dinamic_type_id,
                          ageGroupSelect
                      }) => {
    const store = useStore()

    const [name, setName] = useState(result_dinamic_type_name)
    const [openModal, setOpenModal] = useState(false)

    const removeDinamic = async () => {
        await store.setting_sorev.removeDinamic(result_dinamic_type_id)
        setOpenModal(false)
    }
    const changeName = async () => {
        await store.setting_sorev.changeName(name, result_dinamic_type_id)
    }

    return (
        <>
            <Dialog
                sx={{
                    maxWidth: '100% !important',
                }}
                open={openModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Удалить "{name}" ?
                </DialogTitle>

                <DialogActions>
                    <Button fullWidth variant="contained" color={"primary"}
                            onClick={removeDinamic}>Удалить</Button>
                    <Button fullWidth variant="contained" color={"error"}
                            onClick={() => setOpenModal(false)}>Отмена</Button>
                </DialogActions>
            </Dialog>

            <div className={s.row}>
                <div className={s.row_top}>
                    <div> {index === 0 && <p className={s.title_row}>Для конкретной команды</p>}</div>
                    <FormControl fullWidth sx={{
                        maxWidth: '100%',
                        height: '32px'
                    }}>
                        <Select
                            placeholder={'Дисциплины'}
                            displayEmpty
                            value={null}
                            onChange={async (e) => {
                                await store.setting_sorev.addedDiscipline(e.target.value, result_dinamic_type_id, ageGroupSelect)
                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '32px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Дисциплины</MenuItem>
                            {disciplines.map((el, i) => <MenuItem key={el.discipline_id}
                                                                  disabled={result.some((f) => f.discipline_result_dinamic_id === el.discipline_id)}
                                                                  value={el.discipline_id}>{el.discipline_name}</MenuItem>)}

                        </Select>
                    </FormControl>
                </div>
                <div className={s.row_bottom}>
                    <div className={s.name_box}>
                        <div className={s.action} onClick={() => setOpenModal(true)}>X</div>
                        <input className={s.input} type="text" value={name || ''}
                               onChange={(e) => setName(e.target.value)}/>
                        <div className={s.action} onClick={changeName}>S</div>
                    </div>
                    <div className={s.chosing_items}>
                        {result.map((item, index) => <div
                            className={classNames(s.chosing_items_item, result.length === (index + 1) ? '' : s.chosing_items_item_plus)}
                            key={item.discipline_result_dinamic_id + 'wow'}>
                            <div className={s.chosing_items_item_title}>{item.discipline_short}</div>
                            <div className={s.action}
                                 onClick={async () => await store.setting_sorev.removeDiscipline(item.discipline_result_dinamic_id, ageGroupSelect)}>X
                            </div>
                            {/*{result.length === (index + 1) ? <span className={s.plus}></span> :*/}
                            {/*    <span className={s.plus}>+</span>}*/}
                        </div>)}
                    </div>
                </div>
            </div>
        </>

    );
});

export default Row;
