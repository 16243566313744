import {makeAutoObservable} from "mobx";
import FigureService from "./services/figure.service";
import {toast} from "react-toastify";

export class FigureStore {
    commands = []
    figure = []

    constructor() {
        makeAutoObservable(this)
    }

    setCommands(commands) {
        this.commands = commands
    }

    setFigure(figure) {
        this.figure = figure
    }

    async setScore(score, id, index_box, user_id, old_value, setScore) {
        try {
            if (score === '') return
            const res = await FigureService.setScore(score, id)

            setScore(res.data.value)
            this.setFigure(this.figure.map((f, i) => {
                if (i === index_box) {
                    const data = f?.users.map((user) => user.user_id === user_id ? ({
                        ...user,
                        result_figure: res?.data?.result_figure,
                        scores: user.scores.map((sco) => sco.score_figure_id === id ? ({
                            ...sco,
                            value: res.data.value
                        }) : sco)
                    }) : user)

                    return {...f, users: data}
                } else {
                    return f
                }
            }))
        } catch (e) {
            setScore(old_value || '')
            if (score === '') return
            toast.error('Ошибка')
        }
    }

    async getFigure(id) {
        try {
            const res = await FigureService.getFigure(id)
            this.setFigure(res.data.figures)
            // debugger
        } catch (e) {
            console.log(e)
        }
    }

    async getCommand(id) {
        try {
            const res = await FigureService.getCommand(id)
            this.setCommands({
                ...res.data,
                application_figure: res.data.application_figure.sort((a, b) => a.draw - b.draw)
            })
        } catch (e) {
            console.log(e)
        }
    }

    async getCommandByName(name, id) {
        try {
            const res = await FigureService.getCommandByName(name, id)
            this.setCommands({
                ...res.data,
                application_figure: res.data.application_figure.sort((a, b) => a.draw - b.draw)
            })
        } catch (e) {
            console.log(e)
        }
    }

    async getRecalclulate(id) {
        try {
            const res = await FigureService.getRecalclulate(id)
            return res.data
        } catch (e) {
            toast.error('Ошибка')
        }
    }
}
