import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {useStore} from "../../../hooks/useStore";
import AlertDialog from "../../../components/dialog_modal";

const Row = ({
                 discipline_name,
                 limit_final,
                 index,
                 is_pre_stage,
                 count,
                 setChangedData,
                 changedData,
                 age_group_id,
                 discipline_id,
                 discipline_stage_id,
             }) => {
    const [iseSelected, setIsSelected] = useState(is_pre_stage)
    const [openModal, setOpenModal] = useState(false)
    const [value, setValue] = useState(limit_final || 0)
    const [singleData, setSingleData] = useState([])
    const store = useStore()

    const handleSaveToFinal = async () => {
        await store.setting_sorev.saveToFinal(age_group_id, discipline_id)
      }

    const handleSave = async (data) => {
        await store.setting_sorev.saveDisciplineLeft(data)
    }

    return (
      <>
        <AlertDialog
          title={'Перенести в Финал?'}
          open={openModal} setOpen={setOpenModal} handlerConfirm={() => {
          setOpenModal(false)
          setTimeout(handleSaveToFinal, 500)
        }}/>
        <div className={s.row}>
          <div className={s.row_item} style={{
            justifyContent: 'flex-start'
          }}>{discipline_name}</div>
          <div className={s.row_content}>
            <div className={classNames(s.row_item, index === 0 && s.row_item_title_1)}>{count}</div>
            <div className={classNames(s.row_item, index === 0 && s.row_item_title_2)} style={{
              padding: 0
            }}>
              <input type="number" className={s.input} value={value} onChange={(e) => {
                setValue(e.target.value)

                const findElem = changedData.find((f) => f?.discipline_stage_id === discipline_stage_id)
                if (!findElem) {
                  setChangedData([...changedData, {
                    discipline_stage_id: discipline_stage_id,
                    is_pre_stage: iseSelected,
                    limit_final: e.target.value
                  }])
                } else {
                  setChangedData(changedData.map((el) => el.discipline_stage_id === findElem.discipline_stage_id ? ({
                    ...el,
                    limit_final: e.target.value
                  }) : el))
                }
              }} onBlur={async (e) => {
                  await handleSave([{
                      discipline_stage_id: discipline_stage_id,
                      is_pre_stage: iseSelected,
                      limit_final: e.target.value
                  }])
              }}/>
            </div>
            <div onClick={async () => {
              setIsSelected(!iseSelected)
              const findElem = changedData.find((f) => f?.discipline_stage_id === discipline_stage_id)
                await handleSave([{
                    discipline_stage_id: discipline_stage_id,
                    is_pre_stage: !iseSelected,
                    limit_final: value
                }])

              if (!findElem) {
                setChangedData([...changedData, {
                  discipline_stage_id: discipline_stage_id,
                  is_pre_stage: !iseSelected,
                  limit_final: value
                }])
              } else {
                setChangedData(changedData.map((el) => el.discipline_stage_id === findElem.discipline_stage_id ? ({
                  ...el,
                  is_pre_stage: !iseSelected
                }) : el))
              }

            }}
                 style={{cursor: 'pointer'}}
                 className={classNames(s.row_item, index === 0 && s.row_item_title_3)}>{iseSelected ?
              <span className={s.en}>v</span> : <span className={s.dis}></span>}</div>
            <button className={s.btn_save} onClick={() => setOpenModal(true)} disabled={!iseSelected}>В Финал</button>
          </div>
        </div>
      </>
    );
};

export default Row;
