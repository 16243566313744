import React, {useEffect, useState} from 'react';
import {createPortal} from "react-dom";
import s from './styles.module.css'
import {Button, IconButton} from "@mui/material";
import SportsmenItem from "./sportsmen_item";
import {useStore} from "../../hooks/useStore";

const ModalSportsmen = ({open, onClose, selectedCommand, nameCommand}) => {
    const store = useStore()

    const [commandInfo, setCommandInfo] = useState([])

    useEffect(() => {
        const getInfoCommand = async () => {
            await store.judging.getInfoCommand(selectedCommand)
            setCommandInfo(store.judging.commandInfo)
        }
        getInfoCommand()
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') onClose()
        })

        return () => {
            window.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') onClose()
            })
        }
    })

    return createPortal(
        <div className={s.modal}>
            <div className={s.content}>
                <div className={s.header}>
                    <h3>Спортсмены команды</h3>
                    <h2>{nameCommand}</h2>
                </div>

                <div className={s.content_content}>
                    {commandInfo.map((el) => <SportsmenItem key={el.id} {...el}/>)}
                </div>

                <div className={s.exit} onClick={onClose}>
                    <Button sx={{
                        height: '40px',
                        width: '110px'
                    }} variant="contained">Закрыть</Button>
                </div>
            </div>
        </div>
        , document.body);
};

export default ModalSportsmen;
