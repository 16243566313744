import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {observer} from "mobx-react-lite";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useStore} from "../../../hooks/useStore";

const Header = observer(({ageGroupSelect, setAgeGroupSelect}) => {
    const store = useStore()
    const handleReloadData = async () => {
        await store.setting_sorev.reloadUsersData()
    }
    const handleUploadOrder = async () => {
        await store.setting_sorev.uploadOrder()
    }
    return (
        <div className={s.header}>
            <Wrapper>
                <div className={s.header_split}>
                    <div className={s.header_data}>
                        <FormControl fullWidth sx={{
                            maxWidth: '254px',
                            height: '45px'
                        }}>
                            <Select
                              placeholder={'Возрастная группа'}
                              displayEmpty
                              value={ageGroupSelect}
                              onChange={(e) => {
                                  setAgeGroupSelect(e.target.value)
                              }}
                              inputProps={{'aria-label': 'Without label'}}
                              sx={{
                                  height: '45px',
                                  backgroundColor: '#fff'
                              }}
                              labelId="demo-simple-select-label"
                            >

                                <MenuItem sx={{
                                    display: 'none'
                                }} hidden disabled selected value={null}>Возрастная группа</MenuItem>
                                {store.distipline_settings.age_group.map((el, i) => <MenuItem key={el.age_group_id}
                                                                                              value={el.age_group_id}>{`${el.age_group_name}`}</MenuItem>)}

                            </Select>
                        </FormControl>

                        <div>
                            <h1 className={s.name}>{store?.global?.status?.competition_name || ''}</h1>
                        </div>
                    </div>
                    <div className={s.wrappen_btns_reload}>
                        <div className={s.btn_reload} onClick={handleReloadData}>Обновить данные физлиц</div>
                        <div className={s.btn_reload} onClick={handleUploadOrder}>Загрузить заявки</div>
                    </div>
                </div>
            </Wrapper>

        </div>
    );
});

export default Header;
