import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {useStore} from "../../../hooks/useStore";
import {observer} from "mobx-react-lite";
import Row from "./row";
import AlertDialog from "../../../components/dialog_modal";
import api from "../../../api/api";
import {toast} from "react-toastify";

const Right = observer(({ageGroupSelect}) => {
    const store = useStore()

    const [name, setName] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [disciplineId, setDisciplineId] = useState('')

    const handleAdded = async () => {
        await store.setting_sorev.addedDinamic(name,ageGroupSelect)
        setName('')
    }

    const handleSaveToFinal = async () => {
        await api().get(`general/discipline_result/final/${ageGroupSelect}/${disciplineId}`).then(() => {
            toast.success('Перенесено в финал')
        })
    }

    useEffect(() => {
        const getStatic = async () => {
            await store.setting_sorev.getStatic(ageGroupSelect)
        }

        if (ageGroupSelect === null) return
        getStatic()
    }, [ageGroupSelect])

    useEffect(() => {
        const getDinamic = async () => {
            await store.setting_sorev.getDinamic(ageGroupSelect)
        }

        if (ageGroupSelect === null) return
        getDinamic()
    }, [ageGroupSelect])

    useEffect(() => {
        const getDiscipline = async () => {
            await store.setting_sorev.getDiscipline()
        }

        getDiscipline()
    }, [])

    return (
        <div className={s.right}>
            <AlertDialog
                title={'Перенести в Финал?'}
                open={openModal} setOpen={setOpenModal} handlerConfirm={() => {
                setOpenModal(false)
                handleSaveToFinal()
            }}/>
            <h1 className={s.title}>Итоговые результаты </h1>

            <p className={s.sub_title}>Итог подсчет</p>
            <div className={s.static_res}>
                {store.setting_sorev.static_res.map((el, ind) => {
                    return <div key={el.result_type_id} className={s.static_res_main}>
                        <div className={s.item_name}>{el.result_type_name}</div>
                        <button className={s.btn_save} onClick={() => {
                                setOpenModal(true)
                                setDisciplineId(el.result_type_id)
                        }}>В
                            Финал
                        </button>
                        <div className={s.static_res_sub_main}>
                            {el.result.map((res, i) => <>
                                <div className={s.static_res_sub_main_item}
                                     key={res.discipline_result_id + 'we'}>
                                    {res.discipline_short}

                                </div>
                                {el.result.length === (i + 1) ? '' : <span className={s.plus}>+</span>}
                            </>)}
                        </div>
                    </div>
                })}
            </div>

            <div className={s.dinamic_items}>
                {store.setting_sorev.dynamic_res.map((el, index) => <Row
                    ageGroupSelect={ageGroupSelect}
                    disciplines={store.setting_sorev.discipline}
                    key={el.result_dinamic_type_id}
                                                                         index={index} {...el}/>)}
            </div>

            <div className={s.added_box}>
                <input type="text" value={name} placeholder={'Название типа'}
                       onChange={(e) => setName(e.target.value)}/>

                <div className={s.add} onClick={handleAdded}>
                    +
                </div>
            </div>
        </div>
    );
});

export default Right;
