import React, {useEffect} from "react";
import api from "../../../api/api";
import s from "../0-header/styles.module.css";

const Versions = () => {
    const order = ["MF", "MB", "JF", "JB", "C", "O"];
    const [versions, setVersions] = React.useState([]);

    useEffect(() => {
        updateVersion();
    }, []);

    async function updateVersion() {
        try {
            const res = await api().get("/versions");
            let versions = res.data.module_versions;

            let mfVersion = {
                "module_short": "MF",
                "module_name": "Manager Front",
                "version": process.env.REACT_APP_VERSION
            }

            const allVersions = [...versions, mfVersion];

            let mass = [];
            for (let i = 0; i < order.length; i++) {
                for (let j = 0; j < allVersions.length; j++) {
                    if (allVersions[j].module_short === order[i]) {
                        mass.push(allVersions[j]);
                        break;
                    }
                }
            }

            setVersions(mass);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={s.versions}>{versions.map((el, i) =>
            <pre onClick={updateVersion} title={el.module_name}
                 className={s.version}>{el.module_short + " " + el.version + (i < versions.length - 1 ? " / " : "")}</pre>)}
        </div>
    );
}

export default Versions;