import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../hooks/useStore";
import UploadIcon from '@mui/icons-material/Upload';
import AlertDialog from "../../../../components/dialog_modal";
import api from "../../../../api/api";
import {toast} from "react-toastify";

const SettingReserv = observer(({distiplineSelectFull, discipline_id, age_group_id}) => {
    const store = useStore()

    const [showUsers, setShowUsers] = useState(false)
    const [data, setData] = useState([])
    const [dataCheck, setDataCheck] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [applicationName, setApplicationName] = useState('')
    const [aplicationDisciplineId, setAplicationDisciplineId] = useState(0)
    const [checkAplicationDisciplineId, setCheckAplicationDisciplineId] = useState(false)

    const getReserv = async () => {
        await store.distipline_settings.getListRes((data) => setData(data), {
            age_group_id: age_group_id,
            discipline_id: discipline_id,
            discipline_stage_id: distiplineSelectFull?.discipline_stage_id
        })
    }

    const handleChangeData = (id, path, value) => {
        setData(data?.map((el) => el.competition_application_discipline_id === id ? ({...el, [path]: value}) : el))
    }

    const handleChangeDataDeep = (id, path, value, user_id) => {
        setData(data?.map((el) => el.competition_application_discipline_id === id ? ({
            ...el,
            users: el.users?.map((user) => user.user_id === user_id ? ({...user, [path]: value}) : user)
        }) : el))
    }

    const handleSaveFile = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.synchro'

        await new Promise((resolve) => {
            input.addEventListener('change', resolve);
            input.click();
        });

        const file = input.files[0];

        if (!file || !file.name.endsWith('.synchro')) {
            toast.error('Выбран недопустимый файл. Пожалуйста, выберите файл с расширением .synchro');
            return;
        }

        const formData = new FormData();
        formData.append('coachCard', file);

        try {
            const response = await api().post(`/settings/coach_card_upload/${aplicationDisciplineId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                if (res?.data?.status === 'ok') {
                    toast.success('Карточка сохранена')
                } else {
                    toast.error(`${res?.data?.msg}`)
                }
            });
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    const handleCheckViewButton = async (id) => {
        try {
            const response = await api().get(`/settings/coach_card_upload/check/${id}`)
                .then((res) => {
                if (res?.data?.is_show) {
                    setCheckAplicationDisciplineId(true)
                }
            });
        } catch (error) {
            console.error('Error check view button:', error);
        }
    }

    const handleSave = async () => {
        await store.distipline_settings.saveListRes(() => getReserv(), data?.map((el) => {
            return {
                competition_application_discipline_id: el.competition_application_discipline_id,
                competition_draw: el.competition_draw,
                is_competition_draw_out: !el.is_competition_draw_out ? false : true,
                is_delete: el.is_delete,
                users: el.users?.map((user) => {
                    return {
                        user_id: user?.user_id,
                        is_reserve: user?.is_reserve,
                        is_delete: user?.is_delete
                    }
                })
            }
        }))
    }

    useEffect(() => {
        getReserv()
        handleCheckViewButton(distiplineSelectFull?.discipline_age_group_id)
    }, [])
    return (
        <div className={s.content}>
            <AlertDialog
                title={`Хотите загрузить карточку тренера для ${applicationName}?`}
                open={openModal} setOpen={setOpenModal} handlerConfirm={() => {
                setOpenModal(false)
                handleSaveFile()
            }}/>
            <div className={s.header}>
                <div className={s.show_users} onClick={(e) => setShowUsers(!showUsers)}>
                    <input type="checkbox" checked={showUsers}/>
                    <p>Показать участников</p>
                </div>

                <div className={s.btn} onClick={handleSave}>
                    Сохранить
                </div>
            </div>
            <div className={s.table}>
                {data?.map((el, i) => {
                    return <>
                        <div className={s.header_table} style={{
                            borderTop: i !== 0 && 'none'
                        }}>
                            <div className={classNames(s.item, s.item_action)}
                                 onClick={() => handleChangeData(el.competition_application_discipline_id, 'is_delete', !!!el.is_delete)}
                                 style={{
                                     minWidth: '54px'
                                 }}
                            >
                                <p className={s.item_title}>{el?.is_delete ? 'X' : ''}</p>
                                {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                    cursor: 'default',
                                }} className={s.header_title}>Del</p>}
                            </div>

                            <div className={classNames(s.item, s.item_action)}
                                 onClick={() => handleChangeData(el.competition_application_discipline_id, 'is_competition_draw_out', !!!el.is_competition_draw_out)}
                                 style={{
                                     minWidth: '54px'
                                 }}
                            >
                                <p className={s.item_title}>{el?.is_competition_draw_out ? 'Вн.к' : ''}</p>
                                {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                    cursor: 'default',
                                }} className={s.header_title}>Вн.к</p>}
                            </div>

                            <div className={classNames(s.item, s.item_action)}
                                 style={{
                                     minWidth: '54px',
                                     padding: 0
                                 }}
                            >
                                <p className={s.item_title}>
                                    <input
                                        type="text"
                                        value={el?.competition_draw === null ? '' : el?.competition_draw}
                                        onChange={(event) => {
                                            const input = event.target.value;
                                            const regex = /^-?\d*$/;

                                            let inputValue = input.trim() === '' ? null : input;

                                            if (regex.test(input)) {
                                                handleChangeData(el.competition_application_discipline_id, 'competition_draw', inputValue);
                                            }
                                        }}
                                        className={s.item_title}
                                    />
                                </p>
                                {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                    cursor: 'default',
                                }} className={s.header_title}>Ст. н.</p>}
                            </div>
                            <div className={s.item}
                                 style={{
                                     minWidth: '54px',
                                     background: 'inherit'
                                 }}
                            >
                                {/*<p className={s.item_title}>{'user_name'}</p>*/}
                                {(i === 0 && showUsers) && <p className={s.header_title}>Del</p>}
                            </div>
                            <div className={s.item}
                                 style={{
                                     minWidth: '54px',
                                     background: 'inherit'
                                 }}
                            >
                                {/*<p className={s.item_title}>{'user_name'}</p>*/}
                                {(i === 0 && showUsers) && <p className={s.header_title}>Res</p>}
                            </div>
                            <div className={s.item}
                                 style={{
                                     minWidth: '150px',
                                     background: 'inherit',
                                     paddingLeft: 0
                                 }}
                            >
                                <p className={classNames(s.item_title, s.item_title_city)}>{el?.competition_application_name || ''}</p>
                                {i === 0 && <p className={s.header_title}>Команда / ФИО</p>}
                            </div>
                            <div className={s.item}
                                 style={{
                                     minWidth: '150px',
                                     background: 'inherit',
                                     paddingLeft: 0
                                 }}
                            >
                                {(i === 0 && showUsers) && <p className={s.header_title}>Гр</p>}
                            </div>
                            <div className={s.item}
                                 style={{
                                     minWidth: '150px',
                                     background: 'inherit',
                                     paddingLeft: 0
                                 }}
                            >
                                {(i === 0 && showUsers) && <p className={s.header_title}>Разряд</p>}
                                {checkAplicationDisciplineId &&
                                    <div className={s.btn_upload} onClick={() => {
                                        setOpenModal(true)
                                        setApplicationName(el?.competition_application_name)
                                        setAplicationDisciplineId(el?.competition_application_discipline_id)
                                    }}>
                                        <UploadIcon color={'primary'}></UploadIcon>
                                    </div>
                                }
                            </div>
                        </div>
                        {showUsers && <>
                            {el?.users?.map((user, i) => {
                                return <div key={`${user.user_id}-${i}`}
                                            className={classNames(s.header_table, s.header_table_sub)}>
                                    <div className={classNames(s.item)}
                                         style={{
                                             minWidth: '54px',
                                             background: 'inherit'
                                         }}
                                    >
                                    </div>
                                    <div className={classNames(s.item)}
                                         style={{
                                             minWidth: '54px',
                                             background: 'inherit'
                                         }}
                                    >
                                    </div>
                                    <div className={classNames(s.item)}
                                         style={{
                                             minWidth: '54px',
                                             background: 'inherit'
                                         }}
                                    >
                                    </div>
                                    <div className={classNames(s.item, s.item_action)}
                                         onClick={() => handleChangeDataDeep(el.competition_application_discipline_id, 'is_delete', !!!user?.is_delete, user.user_id)}
                                         style={{
                                             minWidth: '54px',
                                         }}
                                    >
                                        <p className={s.item_title}>{user?.is_delete ? 'X' : ''}</p>
                                    </div>
                                    <div className={classNames(s.item, s.item_action)}
                                         onClick={() => handleChangeDataDeep(el.competition_application_discipline_id, 'is_reserve', !!!user?.is_reserve, user.user_id)}
                                         style={{
                                             minWidth: '54px',
                                         }}
                                    >
                                        <p className={s.item_title}>{user?.is_reserve ? 'R' : ''}</p>
                                    </div>
                                    <div className={s.item}
                                         style={{
                                             minWidth: '150px',
                                         }}
                                    >
                                        <p className={classNames(s.item_title)}>{`${user?.user_fam} ${user?.user_im} ${user?.user_otch}`}</p>
                                    </div>
                                    <div className={s.item}
                                         style={{
                                             minWidth: '150px',
                                         }}
                                    >
                                        <p className={classNames(s.item_title)}>{user?.user_year}</p>
                                    </div>
                                    <div className={s.item}
                                         style={{
                                             minWidth: '150px',
                                         }}
                                    >
                                        <p className={classNames(s.item_title)}>{user?.user_category || ''}</p>
                                    </div>
                                </div>
                            })}
                        </>}
                    </>
                })}
            </div>
        </div>
    );
});

export default SettingReserv;
