import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";
import SettingReserv from "./setting_reserv";

const Reserv = observer(({discipline_id, age_group_id, distiplineSelectFull}) => {
    const store = useStore()

    const [forceUpdate, setForceUpdate] = useState(false)
    const [number, setNumber] = useState(1)

    const setReserv = async () => {
        await store.distipline_settings.setReserv(() => {
            setForceUpdate(true)
            setTimeout(() => setForceUpdate(false), 100)
        }, age_group_id, discipline_id, number, distiplineSelectFull?.discipline_stage_id)
    }
    const downloadLinkReserv = async () => {
        await store.distipline_settings.downloadLinkReserv(age_group_id, discipline_id, distiplineSelectFull?.discipline_stage_id)
    }
    const createReserv = async () => {
        await store.distipline_settings.createReserv(age_group_id, discipline_id, distiplineSelectFull?.discipline_stage_id)
    }

    useEffect(() => {
        setForceUpdate(true)

        setTimeout(() => setForceUpdate(false), 100)
    }, [discipline_id, age_group_id, distiplineSelectFull])

    return (
        <>
            <div className={s.content}>
                {/*<div className={s.content_start}>V Авто</div>*/}
                <div className={s.content_center}>
                    <div className={s.content_center_random} onClick={createReserv}>Сформировать 5 вариантов</div>
                    <div className={s.content_center_download} onClick={downloadLinkReserv}>Скачать Excel с 5 вариантами
                    </div>
                    <div className={s.content_center_number}>Номер жеребевки</div>
                </div>
                <div className={s.content_end}>
                    <input type="number" value={number} min={1} max={5} className={s.input}
                           onChange={(e) => setNumber(e.target.value)}/>
                    <div className={s.content_center_random} onClick={setReserv}>Выбрать</div>
                </div>
            </div>

            {!forceUpdate && <SettingReserv discipline_id={discipline_id} age_group_id={age_group_id}
                                            distiplineSelectFull={distiplineSelectFull}/>}
        </>

    );
});

export default Reserv;
