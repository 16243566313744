import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";
import SettingReserv from "./setting_reserv";

const ReservFigure = observer(({distiplineSelectFull, discipline_id, age_group_id}) => {

    const store = useStore()

    const [forceUpdate, setForceUpdate] = useState(false)
    const [number, setNumber] = useState(1)

    const setReserv = async () => {
        await store.distipline_settings.setReservFigure(
            () => {
                setForceUpdate(true)
                setTimeout(() => setForceUpdate(false), 100)
            }
            , age_group_id, number)
    }
    const downloadLinkReserv = async () => {
        await store.distipline_settings.downloadLinkReservFigure(age_group_id)
    }
    const createReservFigure = async () => {
        await store.distipline_settings.createReservFigure(age_group_id)
    }

    useEffect(() => {
        setForceUpdate(true)

        setTimeout(() => setForceUpdate(false), 100)
    }, [discipline_id, age_group_id, distiplineSelectFull])

    return (
        <>
            <div className={s.content}>
                <div className={s.content_start}>V Авто</div>
                <div className={s.content_center}>
                    <div className={s.content_center_random} onClick={createReservFigure}>Сформировать 5 вариантов</div>
                    <div className={s.content_center_download} onClick={downloadLinkReserv}>Скачать Excel с 5 вариантами
                    </div>
                    <div className={s.content_center_number}>Номер жеребевки</div>
                </div>
                <div className={s.content_end}>
                    <input type="number" value={number} min={1} max={5} className={s.input}
                           onChange={(e) => setNumber(e.target.value)}/>
                    <div className={s.content_center_random} onClick={setReserv}>Выбрать</div>
                </div>
            </div>

            {!forceUpdate && <SettingReserv discipline_id={discipline_id} distiplineSelectFull={distiplineSelectFull}
                                            age_group_id={age_group_id}/>}
        </>

    );
});

export default ReservFigure;
