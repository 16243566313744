import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {CircularProgress, Tooltip} from "@mui/material";
import {useStore} from "../../hooks/useStore";
import AlertDialog from "../dialog_modal";
import {toast} from "react-toastify";
import {number} from "sockjs-client/lib/utils/random";

const TableElement = ({
                          showTooltip = false,
                          children,
                          isEdit = false,
                          disabled = false,
                          onChange,
                          onClick,
                          left,
                          right,
                          className,
                          width,
                          isDD = false,
                          tooltipValue,
                          toFixed = 0,
                          index = null,
                          isPreloader = true,
                          isOpen = false,
                          setListScore,
                      }) => {
    const store = useStore()

    const [edit, setEdit] = useState(false)
    const [editValue, setEditValue] = useState(Number(children))
    const [openModal, setOpenModal] = useState(false)
    // console.log(children)
    useEffect(() => {
        const isInteger = Number(children)?.toString()?.indexOf('.') === -1;
        // console.log(isInteger)
        if (isOpen && isInteger) {
            setEditValue(children !== null ? Number(children)?.toFixed(2) : '')
        } else {
            setEditValue(Number(children))
        }
    }, [edit, store.judging.loadingTable, store.judging.loading])
    return (
        <>
            <AlertDialog
                title={'Вы хотите изменить данное поле?'}
                open={openModal} setOpen={setOpenModal} handlerConfirm={() => {
                setOpenModal(false)
                setTimeout(() => setEdit(true), 100)

            }}/>
            {(!edit && !isOpen) ?
                <Tooltip title={showTooltip ? (tooltipValue ? tooltipValue : children) : null}>
                    <div onClick={() => (onClick && disabled === false) && onClick()}
                         onDoubleClick={() => {
                             ((isEdit && disabled === false && !isDD) && setEdit(true)) ||
                             isDD && setOpenModal(true)
                         }}
                         className={classNames(s.item, className, isEdit && s.edit, !isEdit && s.close_before, disabled && s.disabled)}
                         style={{
                             minWidth: width,
                             maxWidth: width,
                             width,
                             marginLeft: left,
                             marginRight: right,
                         }}>
                        {(store.judging.loading) ? <div className={s.preloader}>
                            <CircularProgress/>
                        </div> : (isNaN(+children) ? children : (!children ? '' : Number(children).toFixed(toFixed)))}
                    </div>
                </Tooltip>
                :
                <input type="number" value={editValue}
                       tabIndex={index}
                       disabled={disabled}
                       onChange={(e) => setEditValue(e.target.value)}
                       style={{
                           minWidth: width,
                           maxWidth: width,
                           width,
                           marginLeft: left,
                           marginRight: right,
                       }}
                       className={classNames(s.item, className, s.input, disabled && s.disabled)}
                       autoFocus
                       step={0.25} max={10} min={0}
                       onKeyDown={(e) => {
                           if (e.key === "Backspace") {
                               if (editValue.toString()?.split('.')?.length === 1 || !editValue.toString()?.split('.')) return

                               const splitNumber = editValue.toString()?.split('.')
                               setTimeout(() => {
                                   setEditValue(+splitNumber[0])
                               }, 0)

                           }
                           if (e.key === 'Enter' && !isOpen) {
                               setEdit(false)
                               onChange(editValue)
                           }
                           if (e.key === 'Enter') {
                               setEdit(false)
                               setListScore(editValue)
                           }
                       }}

                       onFocus={(event) => event.target.select()}

                       onBlur={() => {
                           if (isOpen) {
                               if (Number(editValue) !== Number(children)) {
                                     setEdit(false)
                                     setListScore(+editValue)
                               }
                           } else {
                               setEdit(false)
                               onChange(+editValue)
                           }


                       }}/>}
        </>

    );
};

export default TableElement;
