import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Header from "./header";
import {useStore} from "../../hooks/useStore";
import {observer} from "mobx-react-lite";
import Left from "./left";
import Right from "./right";
import Wrapper from "../../components/wrapper";

const SettingsSorev = observer(() => {
    const store = useStore()
    const [ageGroupSelect, setAgeGroupSelect] = useState(null)


    useEffect(() => {
        const getSettingsAgeGroup = async () => {
            await store.distipline_settings.getSettingsAgeGroup()
        }

        getSettingsAgeGroup()
    }, [])

    return (
        <div>
            <Header setAgeGroupSelect={setAgeGroupSelect} ageGroupSelect={ageGroupSelect}/>

            {(ageGroupSelect !== null) ? <div className={s.content}>
                    <Left ageGroupSelect={ageGroupSelect}/>
                    <Right ageGroupSelect={ageGroupSelect}/>
                </div>
                : <Wrapper>
                    <h1 className={s.empty_text}>Выберите возрастную группу и дисциплину</h1>
                </Wrapper>}
        </div>
    );
});

export default SettingsSorev;
