import React, {useState} from 'react';
import s from './styles.module.css'
import Header from "./header";
import TableMain from "./content/table";
import ImagePage from "./content/images_page";

const Report = () => {
    const [chooseDistipline, setChooseDistiplines] = useState(null)
    const [ageGroupSelect, setAgeGroupSelect] = useState(null)
    const [choosePage, setChoosePage] = useState(1)
    return (
        <div>
            <Header ageGroupSelect={ageGroupSelect} setAgeGroupSelect={setAgeGroupSelect}
                    chooseDistipline={chooseDistipline} setChooseDistiplines={setChooseDistiplines}/>

            <div className={s.navigate} style={{
                gridTemplateColumns: `repeat(2,1fr)`
            }}>
                <div className={s.line} style={{
                    width: `calc(100% / 2)`,
                    left: `${(100 / 2) * (choosePage - 1)}%`
                }}/>
                <div onClick={() => setChoosePage(1)} className={s.navigate_item}>Отчеты</div>
                <div onClick={() => setChoosePage(2)} className={s.navigate_item}>Настройки</div>
            </div>
            {(ageGroupSelect !== null && chooseDistipline !== null) || choosePage !== 1 ? <div className={s.content}>
                {choosePage === 1 && <TableMain ageGroupSelect={ageGroupSelect} chooseDistipline={chooseDistipline}/>}
                {choosePage === 2 && <ImagePage ageGroupSelect={ageGroupSelect} chooseDistipline={chooseDistipline}/>}
            </div> : <h1 className={s.empty}>Выберите возрастную группу и дисциплину</h1>}
        </div>
    );
};

export default Report;