import './App.css';
import Judging from "./page/judging";
import {useStore} from "./hooks/useStore";
import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {Box, FormControl, MenuItem, Modal, Select} from "@mui/material";
import Auth from "./page/auth";
import Versions from "./page/judging/fotter/versions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#EAEAEA',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '3px',
    maxWidth: '70%',
    width: '100%'
};

const ageGroupDisciplines = [
    {title: '1', id: 1},
    {title: '2', id: 2},
    {title: '3', id: 3},
    {title: '4', id: 4},
]

const App = observer(() => {
    const {global} = useStore()
    const [isAuth, setIsAuth] = useState(false)
    const [select, setSelect] = useState(null)
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (isAuth) {
            global.getStatus()
            global.getList()
        }

    }, [isAuth])

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (Boolean(token)) {
            setIsAuth(true)
        } else {
            setIsAuth(false)
        }
        setTimeout(() => setLoading(false), 0)

    }, [])

    if (loading) return null
    if (!isAuth) {
        return <Auth setIsAuth={setIsAuth}/>
    }

    return (
        <div className="App">
            <Judging/>
            <Versions/>
            <Modal
                open={!global.isOpen}
                onClose={() => {
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={'modal'}>
                        <h1>Создайте соревнование</h1>
                    </div>

                    <div className={'action2'}>
                        <FormControl fullWidth sx={{
                            maxWidth: '100%',
                            height: '45px'
                        }}>
                            <Select

                                placeholder={'Выберите соревнование'}
                                displayEmpty
                                value={select}
                                onChange={(e) => {
                                    setSelect(e.target.value)
                                }}
                                inputProps={{'aria-label': 'Without label'}}
                                sx={{
                                    height: '45px',
                                    paddingLeft: '12px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #58A2E4'
                                }}
                                labelId="demo-simple-select-label"
                            >

                                <MenuItem sx={{
                                    display: 'none'
                                }} hidden disabled selected value={null}>Выберите соревнование</MenuItem>
                                {global?.list?.map((el, i) => <MenuItem key={el.id}
                                                                        value={el?.competition_id}>{el?.competition_name}</MenuItem>)}



                            </Select>
                        </FormControl>

                        <textarea
                            value={global?.list?.find((f) => f.competition_id === select) ? global?.list?.find((f) => f.competition_id === select)?.about : value}
                            disabled={true} rows={10}
                            className={'textaera2'}
                            placeholder={'Описание соревнования'}/>

                        <div className={'btn_box'}>
                            <div className={'btn'}
                                 onClick={() => select !== null && global.chooseSelect(select)}>Выбрать
                            </div>
                        </div>
                    </div>


                </Box>
            </Modal>
        </div>
    );
})

export default App;
