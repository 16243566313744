import api from "../../api/api";

class JudgingService {

    async downloadJudgle(idApplicationDiscipline) {
        const res = await api().get(`results/${idApplicationDiscipline}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async getJudgleStatus() {
        const res = await api().get('devices', {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async getAgeGroupDiscipline() {
        const res = await api().get('age_group_discipline', {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async getAllCommand(id_group, id_disciplin, idDisciplineStage) {

        console.log("=== getAllCommand ===");
        console.log("idDisciplineStage =");
        console.log(idDisciplineStage);

        // Временно, костыть. Иногда idDisciplineStage = null
        // idDisciplineStage = 1
        
        const res = await api().get(`application/${id_group}/${id_disciplin}/${idDisciplineStage}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async getInfoCommand(id) {
        const res = await api().get(`users/${id}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async getScoreCommand(id) {
        const res = await api().get(`scores/${id}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async setNewScore(id, value, idCommand) {
        const res = await api().get(`score/${id}/${value}/${idCommand}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async setNewListScore(listScore, idCommand) {
        const res = await api().post(`score_list/${idCommand}`, [...listScore], {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async statusJudging() {
        const res = await api().get(`status`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async clearScore(disciplineId) {
        const res = await api().get(`clean_score/${disciplineId}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async checkJudging(id) {
        const res = await api().get(`confirm/${id}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    // async checkJudging() {
    //     const res = await api().get(`check`, {
    //         headers: {
    //             "accept": "application/json",
    //             "Access-Control-Allow-Origin": "*",
    //         }
    //     })
    //     return res
    // }

    async stopJudging() {
        const res = await api().get(`stop`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async startJudging(idGroup, idDiscipline, discipline_stage_id) {
        const res = await api().get(`start/${idGroup}/${idDiscipline}/${discipline_stage_id || 0}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async doubleStartJudging(idCommand) {
        const res = await api().get(`switch/${idCommand}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async confirmConfirm(idAnswer, idCommand) {
        const res = await api().get(`confirm/${idAnswer}/${idCommand}`, {
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        return res
    }

    async movingStatusNextOrPrev(type) {
        if (type === 'next') {
            const res = await api().get(`next`, {
                headers: {
                    "accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            })
            return res
        }
        if (type === 'prev') {
            const res = await api().get(`previous`, {
                headers: {
                    "accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            })
            return res
        }

    }


}

export default new JudgingService()
