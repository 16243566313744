import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import api from "../../../../api/api";
import ImageUploader from "./upload_image";
import classNames from "classnames";
import {toast} from "react-toastify";

const ImagePage = () => {
    const [dataImage, setDataImage] = useState({})
    const [textData, setTextData] = useState([])

    const handleSave = async () => {
        try {
            const res = await api().post('reports/fields/save', textData?.map((el) => ({
                report_field_id: el?.report_field_id,
                report_field_value: el?.report_field_value
            })))
            // debugger
            toast.success('Данные обновлены')
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
        }
    }

    useEffect(() => {
        const getImages = async () => {
            try {
                const res = await api().get('reports/images')
                const res2 = await api().get('reports/fields')
                setTextData(res2?.data?.report_fields)
                setDataImage({
                    'f': res?.data?.report_images?.find((f) => f?.report_image_id == 1),
                    's': res?.data?.report_images?.find((f) => f?.report_image_id == 2),
                    't': res?.data?.report_images?.find((f) => f?.report_image_id == 3),
                    'l': res?.data?.report_images?.find((f) => f?.report_image_id == 4),
                })
            } catch (e) {
                console.log(e)
            }
        }

        getImages()
    }, [])
    return (
        <div className={s.content}>
            <div className={s.content_top}>
                <div className={s.item}>
                    <ImageUploader img={dataImage?.f}/>
                </div>
                <div className={classNames(s.item, s.item_input)}>
                    <p className={s.title}>Текст</p>
                    {textData?.map((el, i) => {
                        return <div key={i} className={s.input_box}>
                            <p className={s.input_title}>{el?.report_field_name}</p>
                            <input type={"text"}
                                   value={el.report_field_value}
                                   onChange={(e) => {
                                       setTextData(textData?.map((td) => td?.report_field_id === el?.report_field_id ? ({
                                           ...td,
                                           report_field_value: e.target?.value
                                       }) : td))
                                   }}/>
                        </div>
                    })}

                    <div className={s.btn_save_box}>
                        <div className={s.btn_save} onClick={handleSave}>Сохранить</div>
                    </div>

                </div>
                <div className={s.item}>
                    <ImageUploader img={dataImage?.s}/>
                </div>
            </div>

            <div className={s.line}/>
            <div className={s.content_bottom}>
                <div className={classNames(s.item, s.item_l)}>
                    <ImageUploader img={dataImage?.l} width={'100%'}/>
                </div>

                <div className={classNames(s.item, s.item_l)}>
                    <ImageUploader img={dataImage?.t}/>
                </div>
            </div>
        </div>
    );
};

export default ImagePage;
