import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";
import classNames from "classnames";
import api from "../../../api/api";
import {toast} from "react-toastify";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const Judges = observer(({distiplineSelect, ageGroupSelect}) => {
    const store = useStore()

    const [forceUpdatev2, setForceUpdatev2] = useState(false)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [chooseValue, setChooseValue] = useState([])
    const [listSelect, setListSelect] = useState([])
    const [listData, setListData] = useState([])
    const [values, setValues] = useState([])
    const [date, setDate] = useState(null)
    const [assistant, setAssistant] = useState({})
    const [assistantId, setAssistantId] = useState()

    const setSettingsCouch = async () => {
        setForceUpdatev2(true)
        await store.distipline_settings.setSettingsCouch(values)
        setForceUpdatev2(false)
    }

    const handleDateChange = (newValue) => {
        const formattedDate = dayjs(newValue?.$d).format('YYYY-M-D');
        setDate(formattedDate);
    };

    const saveList = async () => {
        try {
            await api().post(`/settings/program/coaches/save`, chooseValue)
            if (date) {
                await api().get(`settings/program/date/save/${date}/${ageGroupSelect}/${distiplineSelect?.discipline_id}/${distiplineSelect?.discipline_stage_id}`)
            }
            if (assistantId) {
                await api().get(`settings/program/assistant/save/${assistantId}/${ageGroupSelect}/${distiplineSelect?.discipline_id}/${distiplineSelect?.discipline_stage_id}`)
            }
            toast.success('Сохранено')
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
        }
    }

    useEffect(() => {
        const getSettingsCouch = async () => {
            await api().get(`settings/program/assistant/${ageGroupSelect}/${distiplineSelect?.discipline_id}/${distiplineSelect?.discipline_stage_id}`).then((res) => {
                setAssistant(res.data)
            })
            await store.distipline_settings.getSettingsCouch(distiplineSelect.discipline_age_group_id)
            const res_data = await api().get(`settings/program/date/${ageGroupSelect}/${distiplineSelect?.discipline_id}/${distiplineSelect?.discipline_stage_id}`)
            setDate(res_data.data.date)
        }
        if (distiplineSelect === null) return
        getSettingsCouch()
    }, [distiplineSelect])

    useEffect(() => {
        setValues(store.distipline_settings.coach?.map((el) => ({
            age_group_discipline_coach_id: el.age_group_discipline_coach_id,
            count: el.count
        })))
    }, [store.distipline_settings.coach])

    useEffect(() => {
        const getListData = async () => {
            function groupById(arr) {
                return arr.reduce((obj, element) => {
                    obj[element.coach_type_id] = obj[element.coach_type_id] || [];
                    obj[element.coach_type_id].push(element);
                    return obj;
                }, {});
            }

            setForceUpdate(true)
            try {
                const res2 = await api().get(`/settings/coaches/`)
                setListSelect(res2.data?.coaches)
                const res = await api().get(`settings/program/coaches/${ageGroupSelect}/${distiplineSelect?.discipline_id}/${distiplineSelect?.discipline_stage_id}`)
                setListData(groupById(res.data?.discipline_coach_list))
                setForceUpdate(false)
            } catch (e) {
                console.log(e)
            }
        }
        getListData()
    }, [ageGroupSelect, distiplineSelect?.discipline_id, distiplineSelect?.discipline_stage_id, forceUpdatev2])

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                {store.distipline_settings.coach?.map((el, i) => {
                    return <div className={s.item_wrapper}>
                        <div key={el.coach_type_id} className={s.item}>
                            <div className={s.coach_type_name}>{el.coach_type_short}</div>
                            <input className={s.input} type="number" min={1} max={5}
                                   onChange={(e) => setValues(values?.map((v) => v.age_group_discipline_coach_id === el.age_group_discipline_coach_id ? ({
                                       ...v,
                                       count: e.target.value
                                   }) : v))}
                                   value={values?.find((f) => f.age_group_discipline_coach_id === el.age_group_discipline_coach_id)?.count}/>

                        </div>

                        {(!forceUpdate && !forceUpdatev2) && <div className={s.item_select_box}>
                            {listData[el.coach_type_id]?.map((coach, i) => {
                                return <select defaultValue={coach?.user_id || ''} key={i} onChange={(e) => {
                                    const findValue = chooseValue?.find(f => +f.discipline_coach_list_id === +coach?.discipline_coach_list_id)
                                    if (findValue) {
                                        setChooseValue(chooseValue?.map((m) => +m.discipline_coach_list_id === +findValue?.discipline_coach_list_id ? ({
                                            ...m,
                                            user_id: e.target.value === '-' ? null : e.target.value
                                        }) : m))
                                    } else {
                                        setChooseValue([...chooseValue, {
                                            discipline_coach_list_id: coach.discipline_coach_list_id,
                                            user_id: e.target.value === '-' ? null : e.target.value
                                        }])
                                    }
                                }
                                }>
                                    <option value={'' || null}>-</option>
                                    {listSelect?.map((sel) => {
                                        return <option
                                            value={sel.id}
                                        >{`${sel.fam} ${sel.im} ${sel.otch}`}</option>
                                    })}
                                </select>
                            })}

                        </div>}

                    </div>
                })}
                <div className={s.date_wrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Дата"
                            value={date && dayjs(date)}
                            format={'DD.MM.YYYY'}
                            sx={{
                                width: '200px',
                                backgroundColor: 'white',
                            }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                    {(!forceUpdate && !forceUpdatev2) &&
                        <div className={s.item_select_assistant}>
                            <select defaultValue={assistant.id || ''}
                                onChange={(e) => {
                                        setAssistantId(e.target.value === '-' ? null : e.target.value)
                                    }
                                }
                            >
                                <option value={null}>
                                    -
                                </option>
                                {assistant.id && 
                                    <option value={assistant.id}>
                                        {assistant.fam} {assistant.im} {assistant.otch}
                                    </option>
                                }
                                {listSelect?.map((sel) => {
                                    return <option
                                        value={sel.id}
                                    >{`${sel.fam} ${sel.im} ${sel.otch}`}</option>
                                })}
                            </select>
                        </div>
                    }
                </div>
            </div>
            <div className={s.btn_wrapper}>
                <div className={s.btn} onClick={setSettingsCouch}>Сохранить</div>
                <div className={classNames(s.btn, s.btn2)} onClick={saveList}>Сохранить</div>
            </div>
        </div>
    );
});

export default Judges;
