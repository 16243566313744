import {useEffect, useState} from "react";
import axios from "axios";
import api from "../../../../../api/api";
import s from '../styles.module.css'
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import {IMAGE_API_URL} from "../../../../../constants";

const ImageUploader = ({img, width}) => {
    const [image, setImage] = useState(null);

    const handleImageClick = (id) => {
        // Открываем диалог выбора файла при клике на изображение
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (e) => handleImageChange(e.target.files[0], id);
        input.click();
    };

    const handleRemove = async (e) => {
        e.stopPropagation()
        try {
            const response = await api().get(`reports/image/clear/${img?.report_image_id}`);
            toast.success('Изображение удалено')
            setImage(null)
        } catch (e) {
            console.log(e)
            toast.success('Ошибка')
        }

    }

    const handleImageChange = (file, id) => {
        // Обработка выбранного изображения

        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                setImage(reader.result);
                try {
                    const formData = new FormData();
                    formData.append('image', file);

                    // Замените URL сервера на ваш собственный
                    const response = await api().post(`reports/image/add/${id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    toast.success('Изображение загружено')

                } catch (error) {
                    toast.success('Ошибка')
                    console.error('Error uploading image', error);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, id) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleImageChange(file, id);
    };

    useEffect(() => {
        if (img?.report_image_file) {
            setImage(`${IMAGE_API_URL}/img/${img?.report_image_file}`)
        }
    }, [img?.report_image_file])

    return (
        <div style={{width: width && '100%'}}>
            <p className={s.title}>{img?.report_image_name || ''}</p>
            <div
                onClick={() => handleImageClick(img?.report_image_id || null)}
                onDragOver={(e) => handleDragOver(e, img?.report_image_id || null)}
                onDrop={(e) => handleDrop(e, img?.report_image_id || null)}
                style={{
                    width: '100%',
                    height: 'auto',
                    border: '1px solid #58A2E4',
                    borderRadius: '3px',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    background: '#fff'
                }}
            >
                {image ? (
                    <div className={s.close_box}>
                        <div className={s.close_icon} onClick={handleRemove}>
                            <CloseIcon/>
                        </div>
                        <img
                            src={image}
                            alt="Uploaded"
                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                        />
                    </div>

                ) : (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        minHeight: '200px',
                        padding: '20px'
                    }}>
                        Кликните или перетащите для загрузки изображения
                    </div>
                )}
            </div>

            <p className={s.comment}>{img?.report_image_comment || ''}</p>
        </div>
    );
};

export default ImageUploader;
