import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import TableItem from "./table_item";
import TableElement from "../../../components/table_element";
import classNames from "classnames";
import {Button, Checkbox} from "@mui/material";
import {useStore} from "../../../hooks/useStore";
import AlertDialog from "../../../components/dialog_modal";
import {toast} from "react-toastify";
import CustomCheckBox from "./CustomCheckBox";

const Table = ({
                   showMoreCommand,
                   isReferee,
                   setNewScore,
                   setNewListScore,
                   selectedCommand,
                   scoreCommand,
                   selectedCommandObject,
                   judgleStatus,
                   setOpenDialogCustom,
                   setCoachTypeId,
                   setCoachTypeNumber,
                   socketData,
                   setJudgleStatus
               }) => {
    const store = useStore()
    const {search} = window.location
    const isOpen = search.includes('open')
    const [openDialog, setOpenDialog] = React.useState(false);
    const [listScore, setListScore] = React.useState([]);

    // penalty helpers
    const penalty = (number) => {
        return scoreCommand?.penalty?.find((f) => f?.penalty_type_id === number) || {}
    }

    // synchro helpers
    const synchro = (number) => {
        return scoreCommand?.synchros?.find((f) => f?.id_synchro_type === number) || {}
    }
    const synchroResoult = (number, positionNumber) => {
        if (scoreCommand?.synchros?.length === 0 || !scoreCommand?.synchros) {
            return {}
        }
        const position = scoreCommand?.synchros?.find((f) => f?.id_synchro_type === number) || {}
        return position?.coach_synchro?.find((f) => f?.number_coach === positionNumber) || {}
    }

    // impressions helpers
    const impressions = (number) => {
        return scoreCommand?.impressions?.find((f) => f?.id_artistic === number) || {}
    }
    const impressionsResoult = (number, positionNumber) => {
        if (scoreCommand?.impressions?.length === 0 || !scoreCommand?.impressions) {
            return {}
        }
        const position = scoreCommand?.impressions?.find((f) => f?.id_artistic === number) || {}
        return position?.coach_artistic?.find((f) => f?.number_coach === positionNumber) || {}
    }

    const clearScore = async () => {
        const disciplineId = selectedCommandObject?.competition_application_discipline_id
        await store.judging.clearScore(disciplineId).then(() => {
            setNewScore(synchroResoult(1, 1)?.id, 0)
            toast.success('Данные очищены')
        }).catch(() => {
            toast.error('Ошибка при очистки данных')
        })
        setOpenDialog(false)
    }

    function addNewScore(array, id, newValue) {
        let found = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                array[i].value = newValue;
                found = true;
                break;
            }
        }

        if (!found && id) {
            array.push({id: id, value: newValue});
        }

        setListScore(array)
    }

    return (
        <div className={s.table} style={{
            display: showMoreCommand && 'none'
        }}>
            <AlertDialog
                title={`Точно хотите очистить все оценки команды ${selectedCommandObject?.competition_application_name} ?`}
                open={openDialog} setOpen={setOpenDialog} handlerConfirm={clearScore}/>

            <>
                {selectedCommand ?
                    <Wrapper>
                        <div className={s.table_content}>
                            <h2 className={s.title}>
                                {selectedCommandObject?.competition_application_name}
                            </h2>
                            {isOpen &&
                                <Button onClick={() => {
                                    setNewListScore(listScore, setListScore)
                                }} sx={{
                                    height: '45px',
                                    width: '200px'
                                }} variant="contained" color="primary">
                                    Сохранить оценки
                                </Button>
                            }
                        </div>
                        {/*<CustomCheckBox/>*/}
                        <div className={s.table_box}>
                            <div>
                                <div className={s.main_table}>
                                    <div className={s.table_title}>
                                        <h3 className={s.table_title_1}>Elements</h3>
                                        <h3 className={classNames(
                                            s.table_title_2,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 1)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 1)?.status === 'connect' && s.connect,
                                        )
                                        }
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(4)
                                                setCoachTypeNumber(1)
                                            }}>DATC</h3>
                                        <h3 className={classNames(
                                            s.table_title_3,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 2)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 2)?.status === 'connect' && s.connect,
                                        )
                                        }
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(4)
                                                setCoachTypeNumber(2)
                                            }}
                                        >DATC</h3>
                                        <h3 className={classNames(
                                            s.table_title_4,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 3)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_4?.find((f) => f.coach_type_number === 3)?.status === 'connect' && s.connect,
                                        )
                                        }
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(4)
                                                setCoachTypeNumber(3)
                                            }}
                                        >DTC</h3>
                                        <h3 className={s.table_title_5}>DD</h3>
                                        <h3 className={s.table_title_5}>FC</h3>
                                        <h3 className={classNames(
                                            s.table_title_6,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 1)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 1)?.status === 'connect' && s.connect,
                                        )}
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(1)
                                                setCoachTypeNumber(1)
                                            }}
                                        >Jd 1</h3>
                                        <h3 className={classNames(
                                            s.table_title_7,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 2)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 2)?.status === 'connect' && s.connect,
                                        )}
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(1)
                                                setCoachTypeNumber(2)
                                            }}
                                        >Jd 2</h3>
                                        <h3 className={classNames(
                                            s.table_title_8,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 3)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 3)?.status === 'connect' && s.connect,
                                        )}
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(1)
                                                setCoachTypeNumber(3)
                                            }}
                                        >Jd 3</h3>
                                        <h3 className={classNames(
                                            s.table_title_9,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 4)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 4)?.status === 'connect' && s.connect,
                                        )}
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(1)
                                                setCoachTypeNumber(4)
                                            }}
                                        >Jd 4</h3>
                                        <h3 className={classNames(
                                            s.table_title_10,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 5)?.status === 'disconnect' && s.disconnect,
                                            judgleStatus.coach_type_1?.find((f) => f.coach_type_number === 5)?.status === 'connect' && s.connect,
                                        )}
                                            onDoubleClick={() => {
                                                setOpenDialogCustom(true)
                                                setCoachTypeId(1)
                                                setCoachTypeNumber(5)
                                            }}
                                        >Jd 5</h3>
                                        <div
                                            className={s.table_title_11}>{Number(scoreCommand?.result_all_elements).toFixed(4)}</div>
                                    </div>

                                    <div className={s.table_items}>
                                        {scoreCommand?.elements?.sort((a, b) => a.number - b.number)?.map((el, i) =>
                                            <TableItem setNewScore={setNewScore}
                                                       addNewScore={addNewScore}
                                                       listScore={listScore}
                                                       isOpen={isOpen}
                                                       index={i}
                                                       isReferee={isReferee}
                                                       key={i} {...el}/>)}
                                    </div>
                                </div>
                                <div className={s.table_bottom}>
                                    <div className={s.table_bottom_left}>
                                        <div className={s.table_bottom_left_title}>
                                            <h3 className={s.table_bottom_left_title_1}>STC</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_2,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 1)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 1)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(3)
                                                    setCoachTypeNumber(1)
                                                }}
                                            >Jd 1</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_3,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 2)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 2)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(3)
                                                    setCoachTypeNumber(2)
                                                }}
                                            >Jd 2</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_4,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 3)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_3?.find((f) => f.coach_type_number === 3)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(3)
                                                    setCoachTypeNumber(3)
                                                }}
                                            >Jd 3</h3>
                                            <div className={s.table_title_11} style={{
                                                marginLeft: '10px'
                                            }}>{Number(scoreCommand?.result_all_synchro).toFixed(2)}</div>
                                        </div>

                                        <div className={s.table_bottom_left_content}>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={175}>{synchro(1)?.name_synchro_type}</TableElement>

                                                <TableElement index={(scoreCommand?.elements?.length + 5)}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(1, 1)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(1, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(1, 1)?.id, value)}
                                                              isEdit={true}>{synchroResoult(1, 1)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 3}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(1, 2)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(1, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(1, 2)?.id, value)}
                                                              isEdit={true}>{synchroResoult(1, 2)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 6}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(1, 3)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(1, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(1, 3)?.id, value)}
                                                              isEdit={true}>{synchroResoult(1, 3)?.value}</TableElement>

                                                <TableElement toFixed={2} left={10}
                                                              width={90}>{synchro(1)?.result_synchro}</TableElement>
                                            </div>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={175}>{synchro(2)?.name_synchro_type}</TableElement>

                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 1}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(2, 1)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(2, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(2, 1)?.id, value)}
                                                              isEdit={true}>{synchroResoult(2, 1)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 4}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(2, 2)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(2, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(2, 2)?.id, value)}
                                                              isEdit={true}>{synchroResoult(2, 2)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 7}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(2, 3)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(2, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(2, 3)?.id, value)}
                                                              isEdit={true}>{synchroResoult(2, 3)?.value}</TableElement>

                                                <TableElement toFixed={2} left={10}
                                                              width={90}>{synchro(2)?.result_synchro}</TableElement>
                                            </div>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={175}>{synchro(3)?.name_synchro_type}</TableElement>

                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 2}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(3, 1)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(3, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(3, 1)?.id, value)}
                                                              isEdit={true}>{synchroResoult(3, 1)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 5}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(3, 2)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(3, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(3, 2)?.id, value)}
                                                              isEdit={true}>{synchroResoult(3, 2)?.value}</TableElement>
                                                <TableElement index={(scoreCommand?.elements?.length + 5) + 8}
                                                              isOpen={isOpen}
                                                              setListScore={(value) => addNewScore(listScore, synchroResoult(3, 3)?.id, value)}
                                                              toFixed={0}
                                                              left={10}
                                                              width={50}
                                                              disabled={!synchroResoult(3, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(synchroResoult(3, 3)?.id, value)}
                                                              isEdit={true}>{synchroResoult(3, 3)?.value}</TableElement>

                                                <TableElement toFixed={2} left={10}
                                                              width={90}>{synchro(3)?.result_synchro}</TableElement>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.table_bottom_right}>
                                        <div className={s.table_bottom_left_title}>
                                            <h3 className={classNames(s.table_bottom_left_title_1, s.table_bottom_right_title_1)}>Art.
                                                Impr</h3>
                                            <h3 className={s.table_bottom_left_title_2}>FC</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_2,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 1)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 1)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(2)
                                                    setCoachTypeNumber(1)
                                                }}
                                            >Jd 1</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_3,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 2)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 2)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(2)
                                                    setCoachTypeNumber(2)
                                                }}
                                            >Jd 2</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_4,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 3)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 3)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(2)
                                                    setCoachTypeNumber(3)
                                                }}
                                            >Jd 3</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_4,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 4)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 4)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(2)
                                                    setCoachTypeNumber(4)
                                                }}
                                            >Jd 4</h3>
                                            <h3 className={classNames(
                                                s.table_bottom_left_title_4,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 5)?.status === 'disconnect' && s.disconnect,
                                                judgleStatus.coach_type_2?.find((f) => f.coach_type_number === 5)?.status === 'connect' && s.connect,
                                            )}
                                                onDoubleClick={() => {
                                                    setOpenDialogCustom(true)
                                                    setCoachTypeId(2)
                                                    setCoachTypeNumber(5)
                                                }}
                                            >Jd 5</h3>
                                            <div className={s.table_title_11} style={{
                                                marginLeft: '10px'
                                            }}>{Number(scoreCommand?.result_all_impression).toFixed(4)}</div>
                                        </div>

                                        <div className={s.table_bottom_left_content}>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={143}>{impressions(1)?.name_artistic}</TableElement>

                                                <TableElement toFixed={2} left={10} width={50}
                                                              isEdit={false}>{impressions(1)?.fc_impression}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(1, 1)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(1, 1)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 9}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(1, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(1, 1)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(1, 1)?.value === null ?
                                                    impressionsResoult(1, 1)?.value_process :
                                                    impressionsResoult(1, 1)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(1, 2)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(1, 2)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 14}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(1, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(1, 2)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(1, 2)?.value === null ?
                                                    impressionsResoult(1, 2)?.value_process :
                                                    impressionsResoult(1, 2)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(1, 3)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(1, 3)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 19}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(1, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(1, 3)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(1, 3)?.value === null ?
                                                    impressionsResoult(1, 3)?.value_process :
                                                    impressionsResoult(1, 3)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(1, 4)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(1, 4)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 24}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(1, 4)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(1, 4)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(1, 4)?.value === null ?
                                                    impressionsResoult(1, 4)?.value_process :
                                                    impressionsResoult(1, 4)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(1, 5)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(1, 5)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 29}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(1, 5)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(1, 5)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(1, 5)?.value === null ?
                                                    impressionsResoult(1, 5)?.value_process :
                                                    impressionsResoult(1, 5)?.value}</TableElement>

                                                <TableElement toFixed={4} left={10}
                                                              width={90}>{impressions(1)?.result_impression}</TableElement>
                                            </div>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={143}>{impressions(2)?.name_artistic}</TableElement>

                                                <TableElement toFixed={2} left={10} width={50}
                                                              isEdit={false}>{impressions(2)?.fc_impression}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(2, 1)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(2, 1)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 10}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(2, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(2, 1)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(2, 2)?.value === null ?
                                                    impressionsResoult(2, 1)?.value_process :
                                                    impressionsResoult(2, 1)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(2, 2)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(2, 2)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 15}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(2, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(2, 2)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(2, 2)?.value === null ?
                                                    impressionsResoult(2, 2)?.value_process :
                                                    impressionsResoult(2, 2)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(2, 3)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(2, 3)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 20}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(2, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(2, 3)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(2, 3)?.value === null ?
                                                    impressionsResoult(2, 3)?.value_process :
                                                    impressionsResoult(2, 3)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(2, 4)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(2, 4)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 25}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(2, 4)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(2, 4)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(2, 4)?.value === null ?
                                                    impressionsResoult(2, 4)?.value_process :
                                                    impressionsResoult(2, 4)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(2, 5)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(2, 5)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 30}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(2, 5)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(2, 5)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(2, 5)?.value === null ?
                                                    impressionsResoult(2, 5)?.value_process :
                                                    impressionsResoult(2, 5)?.value}</TableElement>

                                                <TableElement toFixed={4} left={10}
                                                              width={90}>{impressions(2)?.result_impression}</TableElement>
                                            </div>
                                            <div className={s.table_bottom_left_content_item}>
                                                <TableElement
                                                    width={143}>{impressions(3)?.name_artistic}</TableElement>

                                                <TableElement toFixed={2} left={10} width={50}
                                                              isEdit={false}>{impressions(3)?.fc_impression}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(3, 1)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(3, 1)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 11}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(3, 1)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(3, 1)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(3, 1)?.value === null ?
                                                    impressionsResoult(3, 1)?.value_process :
                                                    impressionsResoult(3, 1)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(3, 2)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(3, 2)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 16}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(3, 2)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(3, 2)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(3, 2)?.value === null ?
                                                    impressionsResoult(3, 2)?.value_process :
                                                    impressionsResoult(3, 2)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(3, 3)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(3, 3)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 21}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(3, 3)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(3, 3)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(3, 3)?.value === null ?
                                                    impressionsResoult(3, 3)?.value_process :
                                                    impressionsResoult(3, 3)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(3, 4)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(3, 4)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 26}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(3, 4)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(3, 4)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(3, 4)?.value === null ?
                                                    impressionsResoult(3, 4)?.value_process :
                                                    impressionsResoult(3, 4)?.value}</TableElement>
                                                <TableElement isOpen={isOpen}
                                                              className={impressionsResoult(3, 5)?.value === null ? s.process_digit : s.main_digit}
                                                              setListScore={(value) => addNewScore(listScore, impressionsResoult(3, 5)?.id, value)}
                                                              index={(scoreCommand?.elements?.length + 5) + 31}
                                                              toFixed={2} left={10}
                                                              width={50}
                                                              disabled={!impressionsResoult(3, 5)?.id || isReferee}
                                                              onChange={(value) => setNewScore(impressionsResoult(3, 5)?.id, value)}
                                                              isEdit={true}>{impressionsResoult(3, 5)?.value === null ?
                                                    impressionsResoult(3, 5)?.value_process :
                                                    impressionsResoult(3, 5)?.value}</TableElement>

                                                <TableElement toFixed={4} left={10}
                                                              width={90}>{impressions(3)?.result_impression}</TableElement>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={s.main_table_right}>
                                <h3>Penalty</h3>

                                {scoreCommand?.penalty?.map((el, i) => {
                                    return <div className={s.main_tible_right_item}>
                                        <TableElement showTooltip
                                                      left={15}
                                                      width={78}>{penalty(i + 1)?.penalty_type_name}</TableElement>
                                        <TableElement toFixed={4} left={6} width={90} isEdit={true}
                                                      disabled={!penalty(i + 1)?.id || isReferee}
                                                      onChange={(value) => setNewScore(penalty(i + 1)?.id, value)}>{penalty(i + 1)?.value}</TableElement>
                                    </div>
                                })}

                                <div className={s.main_table_score}>
                                    <h3>Score</h3>

                                    <div className={s.main_table_score_score}>
                                        {Number(scoreCommand?.result_total).toFixed(4)}
                                    </div>
                                </div>
                                <div className={classNames(s.main_table_score, s.main_table_score_sub)}>
                                    <h3>{scoreCommand?.result_other_title}</h3>

                                    <div
                                        className={classNames(s.main_table_score_score, s.main_table_score_score_sub)}>
                                        {Number(scoreCommand?.result_other).toFixed(4)}
                                    </div>
                                </div>
                                <div className={classNames(s.main_table_score, s.main_table_score_sub)}>
                                    <h3 style={{fontWeight: '700'}}>{scoreCommand?.result_sum_title}</h3>

                                    <div style={{fontWeight: '700'}}
                                         className={classNames(s.main_table_score_score, s.main_table_score_score_sub)}>
                                        {Number(scoreCommand?.result_sum).toFixed(4)}
                                    </div>
                                </div>
                                {isReferee !== true ?
                                    <div className={s.clear_score} onClick={() => {
                                        setOpenDialog(true)
                                    }}>
                                        Очистить оценки
                                    </div> :
                                    <></>
                                }

                                {/*<div className={s.main_tible_right_item} style={{*/}
                                {/*    marginTop: '45px'*/}
                                {/*}}>*/}
                                {/*    <TableElement left={15}*/}
                                {/*                  showTooltip*/}
                                {/*                  width={78}>Other result</TableElement>*/}
                                {/*    <TableElement toFixed={4} left={6} width={90} isEdit={true}*/}
                                {/*                  disabled={!scoreCommand?.result_other_id || isReferee}*/}
                                {/*                  onChange={(value) => setNewScore(scoreCommand?.result_other_id, value)}>{scoreCommand?.result_other}</TableElement>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </Wrapper>
                    : <div className={s.empty}>
                        Выберите команду
                    </div>
                }
            </>
        </div>
    );
};

export default Table;
